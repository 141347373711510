import React, { useMemo } from "react";
import Viewer from "react-viewer";

function ImageViewer({ openLightbox, setOpenLightbox, lightboxSrcs }) {
  const images = useMemo(() => [{ src: lightboxSrcs[0]?.src, alt: "" }], [lightboxSrcs]);

  return (
    <Viewer
      visible={openLightbox}
      onClose={() => {
        setOpenLightbox(false);
      }}
      images={images}
    />
  );
}

export default ImageViewer;
