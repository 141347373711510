import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import BChatLogo from "../images/buildhub-chat-logo.png";
import Background from "../images/bg.png";
import { common } from "../util/common";
import showSwal from "../components/swal";
import ApiService from "../api/apiService";
import { useNavigate } from "react-router-dom";
import { saveTokenToCookies, saveUserDetailsToLocalStorage } from "../hooks/accountHandler";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ErrorMessage = styled.div`
  color: #d32f2f; // Red color for the error message text
  font-size: 0.875rem;
  margin-top: 1rem;
  text-align: center;
  padding: 0.75rem 1rem; // Added padding for better spacing
  border: 1px solid #d32f2f; // Red border around the message
  background-color: rgba(211, 47, 47, 0.1); // Light red background for contrast
  border-radius: 8px; // Rounded corners for a smoother appearance
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  animation: ${fadeIn} 0.5s ease-in-out; // Fade-in effect for the error message
  font-weight: 500; // Slightly bolder font for emphasis
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url(${Background});
  background-size: cover;
  background-position: center;
  font-family: "Arial", sans-serif;
  padding: 1rem;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(211, 47, 47, 0.1);
    z-index: 1;
  }

  /* Ensure content inside the container is on top of the overlay */
  > * {
    position: relative;
    z-index: 2;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 16px;
  width: 90%;
  max-width: 1400px;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const DescriptionContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Description = styled.div`
  flex: 1;
  padding: 3rem;
  background: #fef5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & img {
    max-width: 150px;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      max-width: 120px;
    }
  }

  & h2 {
    color: #d32f2f;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  & p {
    color: #555;
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 2rem;
    line-height: 1.6;
  }

  & .download-buttons {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;

    @media (max-width: 768px) {
      flex-direction: row;
      width: 100%;
      gap: 1rem;
    }

    & a {
      text-decoration: none;
      background: #d32f2f;
      color: #ffffff;
      padding: 1rem 2rem;
      border-radius: 12px;
      font-size: 1.3rem;
      font-weight: bold;
      text-align: center;
      width: fit-content;
      box-shadow: 0 4px 8px rgba(211, 47, 47, 0.4);
      transition: all 0.3s ease;

      &:hover {
        background: #b71c1c;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;

const LoginForm = styled.div`
  flex: 1;
  padding: 3rem;

  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const Title = styled.h1`
  text-align: center;
  color: #d32f2f;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const InputGroup = styled.div`
  margin-bottom: 2rem;
  position: relative;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  color: #555;
`;

const Input = styled.input`
  width: 100%;
  padding: 1.6rem;
  border: 1px solid #ddd;
  border-radius: 12px;
  font-size: 1.5rem;
  transition: all 0.3s ease;

  &:focus {
    border-color: #ff4b5c;
    box-shadow: 0 0 8px rgba(255, 75, 92, 0.4);
    outline: none;
  }
`;

const PasswordToggle = styled.button`
  position: absolute;
  top: 60%;
  right: 16px;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.3rem;
  color: #d32f2f;
  cursor: pointer;
`;

const RememberMeGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  & > div {
    display: flex;
    align-items: center;
  }
`;

const Checkbox = styled.input`
  margin-right: 0.8rem;
  width: 20px;
  height: 20px;
  accent-color: #d32f2f;
`;

const Link = styled.a`
  color: #d32f2f;
  text-decoration: none;
  font-size: 1.1rem;

  &:hover {
    text-decoration: underline;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 1rem;
  font-size: 1.4rem;
  border: none;
  border-radius: 12px;
  background: #d32f2f;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #b71c1c;
    box-shadow: 0 4px 12px rgba(211, 47, 47, 0.4);
  }

  &:disabled {
    background: #f0f0f0;
    color: #a0a0a0;
    cursor: not-allowed;
    box-shadow: none;
  }
`;

const Footer = styled.p`
  text-align: center;
  margin-top: 2rem;
  font-size: 1rem;
  color: #888;

  & > a {
    color: #d32f2f;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Backdrop Styling
const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 450; /* Positioned behind the loader */
`;

// Loader Wrapper
const LoaderWrapper = styled.div`
  position: fixed; /* Make sure it's fixed at the center of the screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500; /* Ensure loader is above the backdrop */
`;

// Loader Styling
const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #d32f2f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
  z-index: 505;
`;
const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [mobileVersionUrl, setMobileVersionUrl] = useState("#");
  const [appVersion, setAppVersion] = useState("");
  const [underMaintenance, setUnderMaintenace] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const handleTogglePassword = () => setPasswordVisible(!passwordVisible);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login();
  };

  const login = async () => {
    try {
      if (!formData.email || !formData.password) return setError("Email Address & Password cannot be blank.");
      setIsLoading(true);
      const response = await ApiService.account.login(formData.email, formData.password, formData.rememberMe);
      const { success, message, token, userDetails } = response;
      setIsLoading(false);
      if (!success) return setError(message);
      saveTokenToCookies(token, formData.rememberMe);
      saveUserDetailsToLocalStorage(userDetails);

      navigate("/");
    } catch (err) {
      alert(err);
      setIsLoading(false);
      showSwal(common.errors.LOGIN_FAILED);
    }
  };

  useEffect(() => {
    const getCurrentVersion = async () => {
      try {
        const version = await ApiService.version.versionControl();
        if (!version || !version.MOBILE_VERSION || !version.APP_VERSION) {
          setError("Server is under maintenance. Please try again later.");
          setUnderMaintenace(true);
        } else {
          const newMobileVersionUrl = `https://backend-systems.buildhubware.com/apk/Bchat_App_Versions/${version.MOBILE_VERSION}/bchat-app.apk`;
          setMobileVersionUrl(newMobileVersionUrl);
          setAppVersion(version.APP_VERSION);
        }
      } catch (err) {
        setError("Server is under maintenance. Please try again later.");
        setUnderMaintenace(true);
      }
    };
    getCurrentVersion();
  }, []);

  return (
    <Container>
      {isLoading && (
        <LoaderWrapper>
          <Backdrop />
          <Loader />
        </LoaderWrapper>
      )}
      <ContentWrapper>
        <Description>
          <img src={BChatLogo} alt='Company Logo' />
          <DescriptionContainer>
            <h2>Buildhub Chat</h2>
            <p>v{appVersion}</p>
            <p>Innovation. Service. Accountability. Virtue. Excellence.</p>
            <p>Stay connected. Stay productive. Build success together with Buildhub Chat.</p>
          </DescriptionContainer>
          <div className='download-buttons'>
            <a href={mobileVersionUrl}>Download for Android</a>
            <a href='#'>Download for iOS</a>
          </div>
        </Description>
        <LoginForm>
          <Title>Login to Your Account</Title>

          {error && <ErrorMessage>{error}</ErrorMessage>}

          <form onSubmit={handleSubmit}>
            <InputGroup>
              <InputLabel>Email Address</InputLabel>
              <Input
                type='email'
                name='email'
                placeholder='Enter your email'
                value={formData.email}
                onChange={handleChange}
                disabled={underMaintenance}
                required
              />
            </InputGroup>
            <InputGroup>
              <InputLabel>Password</InputLabel>
              <Input
                type={passwordVisible ? "text" : "password"}
                name='password'
                placeholder='Enter your password'
                value={formData.password}
                onChange={handleChange}
                disabled={underMaintenance}
                required
              />
              <PasswordToggle type='button' onClick={handleTogglePassword}>
                {passwordVisible ? "Hide" : "Show"}
              </PasswordToggle>
            </InputGroup>
            <RememberMeGroup>
              <div>
                <Checkbox type='checkbox' name='rememberMe' checked={formData.rememberMe} onChange={handleChange} />
                <span>Remember Me</span>
              </div>
            </RememberMeGroup>
            <SubmitButton type='submit' disabled={underMaintenance}>
              Log In
            </SubmitButton>
          </form>
          <Footer>
            <p>JJMJF &#169; 2024 All Rights Reserved. </p>
          </Footer>
        </LoginForm>
      </ContentWrapper>
    </Container>
  );
};

export default Login;
