import heartReaction from "../../images/reactions/heart-reaction.gif";
import hahaReaction from "../../images/reactions/haha-reaction.gif";
import likeReaction from "../../images/reactions/like-reaction.gif";
import thanksReaction from "../../images/reactions/thanks-reaction.png";
import sadReaction from "../../images/reactions/sad-reaction.gif";
import React, { useState, useRef, useEffect, useContext } from "react";
import { ChatContext, WebSocketContext } from "../../context/contexts";
import ApiService from "../../api/apiService";
import { common } from "../../util/common";
import showSwal from "../swal";

function ReactionOptions({ chat_id, receiver, reaction, userDetails, toggleShowReactionOptions }) {
  const Chat = useContext(ChatContext);
  const WS = useContext(WebSocketContext);

  const handleReactionClick = async (e) => {
    const target = e.target;

    const reactionType = target.getAttribute("reaction");

    const reactionResponse = await ApiService.message.addRemoveReaction(Chat.storedConversationId, chat_id, {
      reaction_type: reactionType,
    });

    const { success, message_reaction } = reactionResponse;
    if (!success) return showSwal(common.errors.ADD_REMOVE_REACTION_ERROR);
    WS.websocketInstance.sendMsgReaction(
      { message: message_reaction, type: "user-reactions-chat" },
      Chat.storedConversationId
    );
    toggleShowReactionOptions();
  };

  const isHahaReact = reaction?.haha?.find((reactions) => reactions.user_id === userDetails.id);
  const isHeartReact = reaction?.heart?.find((reactions) => reactions.user_id === userDetails.id);
  const isThanksReact = reaction?.thanks?.find((reactions) => reactions.user_id === userDetails.id);
  const isLikeReact = reaction?.like?.find((reactions) => reactions.user_id === userDetails.id);
  const isSadReact = reaction?.sad?.find((reactions) => reactions.user_id === userDetails.id);

  return (
    <div className='modal-container'>
      <div className='modal-dialog'>
        <button className='close-btn' onClick={(e) => toggleShowReactionOptions()}>
          ✕
        </button>

        <div className='modal-msg-reactions'>
          <h2 className='modal-title'>Select Your Reaction</h2>
          <h3 className='reaction-title'>Choose Your Reaction</h3>
          <div className='reaction-icons'>
            <div
              className='reaction-icon'
              role='button'
              tabIndex={0}
              onClick={(e) => handleReactionClick(e)}
              onKeyDown={(e) => e.key === "Enter" && handleReactionClick(e)}
              reaction='heart'
            >
              <img
                src={heartReaction}
                alt='Heart Reaction'
                reaction='heart'
                className={`like-reaction ${isHeartReact ? "pulse pulse-bg" : ""}`}
              />
            </div>
            <div
              className='reaction-icon'
              role='button'
              tabIndex={0}
              onClick={(e) => handleReactionClick(e)}
              onKeyDown={(e) => e.key === "Enter" && handleReactionClick(e)}
              reaction='haha'
            >
              <img
                src={hahaReaction}
                alt='Haha Reaction'
                reaction='haha'
                className={`like-reaction ${isHahaReact ? "pulse pulse-bg" : ""}`}
              />
            </div>
            <div
              className='reaction-icon'
              role='button'
              tabIndex={0}
              onClick={(e) => handleReactionClick(e)}
              onKeyDown={(e) => e.key === "Enter" && handleReactionClick(e)}
              reaction='thanks'
            >
              <img
                src={thanksReaction}
                alt='Thanks Reaction'
                reaction='thanks'
                className={`like-reaction ${isThanksReact ? "pulse pulse-bg" : ""}`}
              />
            </div>
            <div
              className='reaction-icon'
              role='button'
              tabIndex={0}
              onClick={(e) => handleReactionClick(e)}
              onKeyDown={(e) => e.key === "Enter" && handleReactionClick(e)}
              reaction='like'
            >
              <img
                src={likeReaction}
                alt='Like Reaction'
                reaction='like'
                className={`like-reaction ${isLikeReact ? "pulse pulse-bg" : ""}`}
              />
            </div>
            <div
              className='reaction-icon'
              role='button'
              tabIndex={0}
              onClick={(e) => handleReactionClick(e)}
              onKeyDown={(e) => e.key === "Enter" && handleReactionClick(e)}
              reaction='sad'
            >
              <img
                src={sadReaction}
                alt='Sad Reaction'
                reaction='sad'
                className={`like-reaction ${isSadReact ? "pulse pulse-bg" : ""}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReactionOptions;
