const ERROR_TITLE = "Something went wrong";
const ERROR_ICON = "error";
const ERROR_BTN = "OK";

const SUCCESS_ICON = "success";

export const common = {
  errors: {
    LOGIN_FAILED: {
      title: "Login Failed",
      icon: ERROR_ICON,
      text: "",
      confirmButtonText: ERROR_BTN,
    },
    DELETE_CONVERSATION_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00001",
      confirmButtonText: ERROR_BTN,
    },
    LEAVE_CONVERSATION_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00002",
      confirmButtonText: ERROR_BTN,
    },
    CONVERSATION_FETCH_USERNAME_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00003",
      confirmButtonText: ERROR_BTN,
    },
    CREATE_CONVERSATION_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00004",
      confirmButtonText: ERROR_BTN,
    },
    UPDATE_CONVERSATION_DATA_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00005",
      confirmButtonText: ERROR_BTN,
    },
    SET_CONVERSATION_DATA_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00006",
      confirmButtonText: ERROR_BTN,
    },
    SEND_MESSAGE_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00007",
      confirmButtonText: ERROR_BTN,
    },
    DELETE_MESSAGE_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00008",
      confirmButtonText: ERROR_BTN,
    },
    SEARCH_MESSAGE_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00009",
      confirmButtonText: ERROR_BTN,
    },
    UPDATE_CONVERSATION_MEMBER_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00010",
      confirmButtonText: ERROR_BTN,
    },
    ADD_CONVERSATION_MEMBER_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00011",
      confirmButtonText: ERROR_BTN,
    },
    VIEW_CONVERSATION_FILES_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00012",
      confirmButtonText: ERROR_BTN,
    },
    INVALID_CONVERSATION: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Invalid Conversation. Please contact support. EREF#00013",
      confirmButtonText: ERROR_BTN,
    },
    EMPTY_MESSAGE: {
      title: "Message cannot be empty.",
      icon: ERROR_ICON,
      text: "Empty Message. Please contact support. EREF#00014",
      confirmButtonText: ERROR_BTN,
    },
    UPDATE_CONVERSATION_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00015",
      confirmButtonText: ERROR_BTN,
    },
    FETCH_USERDATA_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00016",
      confirmButtonText: ERROR_BTN,
    },
    UPDATE_USERDATA_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00017",
      confirmButtonText: ERROR_BTN,
    },
    FETCH_THREAD_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00018",
      confirmButtonText: ERROR_BTN,
    },
    FETCH_MSG_POSITION_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00019",
      confirmButtonText: ERROR_BTN,
    },
    ADD_REMOVE_REACTION_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00020",
      confirmButtonText: ERROR_BTN,
    },
    GLOBAL_SEARCH_RESULT_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00021",
      confirmButtonText: ERROR_BTN,
    },
    GLOBAL_SEARCH_RESULT_CLICK_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00022",
      confirmButtonText: ERROR_BTN,
    },
    SEND_MESSAGE_FILE_LARGE_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "File Attachment too large. Max: 15MB. Please contact support. EREF#00023",
      confirmButtonText: ERROR_BTN,
    },
    PIN_CONVERSATION_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00024",
      confirmButtonText: ERROR_BTN,
    },
    FETCH_NOTIFICATION_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00025",
      confirmButtonText: ERROR_BTN,
    },
    UPDATE_NOTIFICATION_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00026",
      confirmButtonText: ERROR_BTN,
    },
    CLICK_NOTIFICATION_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00027",
      confirmButtonText: ERROR_BTN,
    },
    FETCH_MY_FORMS_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00028",
      confirmButtonText: ERROR_BTN,
    },
    FETCH_FORM_DETAIL_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00029",
      confirmButtonText: ERROR_BTN,
    },
    PERMISSION_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "You don't have enough permission to continue. Please contact support. EREF#00030",
      confirmButtonText: ERROR_BTN,
    },
    SUBMIT_EPR_FORM_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00031",
      confirmButtonText: ERROR_BTN,
    },
    UNFILLED_INPUTS_DATA_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please fill out all required fields in red outline. EREF#00032",
      confirmButtonText: ERROR_BTN,
    },
    GETTING_MY_REQUESTS_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00033",
      confirmButtonText: ERROR_BTN,
    },
    GETTING_REQUESTS_DETAIL_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00034",
      confirmButtonText: ERROR_BTN,
    },
    GETTING_APPROVAL_LIST_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00035",
      confirmButtonText: ERROR_BTN,
    },
    APPROVE_REQUEST_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00036",
      confirmButtonText: ERROR_BTN,
    },
    PAGE_PERMISSIONS_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00037",
      confirmButtonText: ERROR_BTN,
    },
    FETCH_ACTIVITY_LOGS_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00038",
      confirmButtonText: ERROR_BTN,
    },
    DIRECT_TO_MESSAGE_ERROR: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Please contact support. EREF#00039",
      confirmButtonText: ERROR_BTN,
    },
    DIRECT_TO_MESSAGE_REQUIRED_FIELDS: {
      title: ERROR_TITLE,
      icon: ERROR_ICON,
      text: "Invalid Recipients or Message. Please contact support. EREF#00040",
      confirmButtonText: ERROR_BTN,
    },
  },
  success: {
    LOGIN_SUCCESS: {
      title: "Login Successful",
      icon: SUCCESS_ICON,
      text: "",
      confirmButtonText: ERROR_BTN,
    },
    CONVERSATION_CREATED: {
      title: "Success",
      icon: SUCCESS_ICON,
      text: "Conversation created.",
      confirmButtonText: ERROR_BTN,
    },
    CONVERSATION_UPDATED: {
      title: "Success",
      icon: SUCCESS_ICON,
      text: "Conversation updated.",
      confirmButtonText: ERROR_BTN,
    },
    CHANGED_PASSWORD: {
      title: "Success",
      icon: SUCCESS_ICON,
      text: "",
      confirmButtonText: ERROR_BTN,
    },
    UPDATE_USER_DETAILS: {
      title: "Success",
      icon: SUCCESS_ICON,
      text: "User details updated.",
      confirmButtonText: ERROR_BTN,
    },
    DIRECT_MESSAGE_SENT: {
      title: "Success",
      icon: SUCCESS_ICON,
      text: "Message sent!",
      confirmButtonText: ERROR_BTN,
    },
  },
};
