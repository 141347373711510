import heartReaction from "../../images/reactions/heart-reaction.gif";
import hahaReaction from "../../images/reactions/haha-reaction.gif";
import likeReaction from "../../images/reactions/like-reaction.gif";
import thanksReaction from "../../images/reactions/thanks-reaction.png";
import sadReaction from "../../images/reactions/sad-reaction.gif";
import React, { useState, useRef, useEffect, useContext } from "react";

function ReactionsIndicator({ reaction, reaction_details, isThread }) {
  const [showReactionList, setShowReactionList] = useState(false);

  const handleMouseEnter = () => {
    setShowReactionList(true);
  };

  const handleMouseLeave = () => {
    setShowReactionList(false);
  };

  let reactionImg;
  switch (reaction) {
    case "haha":
      reactionImg = hahaReaction;
      break;
    case "heart":
      reactionImg = heartReaction;
      break;
    case "like":
      reactionImg = likeReaction;
      break;
    case "thanks":
      reactionImg = thanksReaction;
      break;
    case "sad":
      reactionImg = sadReaction;
      break;
  }

  return (
    <div
      className='centered-items chat-reactions-border'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={reactionImg} className='like-reaction' />
      <small className='reactions-indicator'>{reaction_details?.length}</small>
      {showReactionList && (
        <div className={`reactions-list ${isThread ? "thread-reaction-list" : ""}`}>
          {reaction_details?.map((reactor, index) => (
            <div className='reaction-user' key={index}>
              {reactor?.user?.display_name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ReactionsIndicator;
