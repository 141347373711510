import React, { useState, useEffect, useRef } from "react";
import "./SendPrivateMessageModal.css";
import showSwal from "./swal";
import ApiService from "../api/apiService";
import { common } from "../util/common";

const SendPrivateMessageModal = ({
  closeModal,
  sendMessage,
  setPrivateMessageModalShow,
  privateMessageModalData,
  setPrivateMesageModalData,
}) => {
  const [recipients, setRecipients] = useState([]);
  const [message, setMessage] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const [allUsers, setAllUsers] = useState([]);

  const [isSendingMessage, setSendingMessage] = useState(false);
  const receiverInput = useRef(null);
  const sendMessageBtn = useRef(null);

  useEffect(() => {
    if (privateMessageModalData) {
      setRecipients([{ id: privateMessageModalData?.id, name: privateMessageModalData?.display_name }]);
    }
  }, [privateMessageModalData]);
  useEffect(() => {
    const fetchUserNames = async () => {
      try {
        const response = await ApiService.users.getAllusers();
        const { success, users } = response;
        if (success) {
          setAllUsers(users);
        } else {
          showSwal(common.errors.CONVERSATION_FETCH_USERNAME_ERROR);
        }
      } catch (error) {
        console.error("Error fetching user names:", error);
      }
    };

    fetchUserNames();
  }, []);

  const handleAddRecipient = ({ id, name }) => {
    if (!recipients.some((recipient) => recipient.id === id)) {
      setRecipients((prevRecipients) => [...prevRecipients, { id, name }]);
      receiverInput.current.value = "";
      receiverInput.current.focus();
    }
    setSuggestions([]);
  };

  const handleRemoveRecipient = (id) => {
    setRecipients((prevRecipients) => prevRecipients.filter((recipient) => recipient.id !== id));
  };

  const handleSearch = (query) => {
    const normalizedQuery = query.trim().toLowerCase();

    if (normalizedQuery) {
      setSuggestions(allUsers.filter((user) => user?.display_name?.toLowerCase().includes(normalizedQuery)));
    } else {
      setSuggestions([]);
    }
  };

  const handleSend = async () => {
    if (!message || message === "" || recipients.length === 0) {
      return showSwal(common.errors.DIRECT_TO_MESSAGE_REQUIRED_FIELDS);
    }

    sendMessageBtn.current.innerText = "Sending...";
    setSendingMessage(true);
    const recipientIds = recipients.map((recipient) => recipient.id);
    const request = {
      recipients: recipientIds,
      message: message,
    };

    if (!isSendingMessage) {
      const {
        success,
        message: messageResponse,
        conversation_id,
      } = await ApiService.directMessage.sendDirectMessage(request);
      if (!success) return showSwal(common.errors.DIRECT_TO_MESSAGE_ERROR);

      sendMessageBtn.current.innerText = "Send Message";
      setSendingMessage(false);
      showSwal(common.success.DIRECT_MESSAGE_SENT).then(() => {
        window.location.href = `/conversations/${conversation_id}`;
      });
    }
  };

  return (
    <div className='spm-modal-backdrop'>
      <div className='spm-modal-container'>
        <button
          className='spm-close-btn'
          onClick={() => {
            setPrivateMesageModalData(null);
            setPrivateMessageModalShow(false);
          }}
        >
          X
        </button>
        <div className='spm-to-section'>
          <label className='spm-to-label'>To:</label>
          <div className='spm-recipients-container'>
            {recipients.map((recipient, index) => (
              <span key={`${index}-${recipient.id}`} className='spm-recipient-chip'>
                {recipient.name}{" "}
                <button className='spm-remove-recipient' onClick={() => handleRemoveRecipient(recipient.id)}>
                  &times;
                </button>
              </span>
            ))}
            <input
              type='text'
              className='spm-recipient-input'
              placeholder='Type a name...'
              onChange={(e) => handleSearch(e.target.value)}
              ref={receiverInput}
            />
            {suggestions.length > 0 && (
              <ul className='spm-suggestions-list'>
                {suggestions.map((suggestion, index) => (
                  <li
                    key={`${index}-${suggestion.id}`}
                    className='spm-suggestion-item'
                    onClick={() => handleAddRecipient({ id: suggestion.id, name: suggestion.display_name })}
                  >
                    {suggestion.display_name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className='spm-message-section'>
          <label className='spm-message-label'>Message:</label>
          <textarea
            className='spm-message-textarea'
            rows='5'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder='Type your message here...'
          />
        </div>

        <button className='spm-send-btn' onClick={handleSend} ref={sendMessageBtn}>
          Send Message
        </button>
      </div>
    </div>
  );
};

export default SendPrivateMessageModal;
