import React, { useState, useRef, useEffect, useContext } from "react";
import "../styles/user-hover-preview.css";
import emptyImage from "../images/empty.jpg";
import chatImage from "../images/chat.png";
import ApiService from "../api/apiService";
import showSwal from "./swal";
import { common } from "../util/common";

function UserHoverPreview({
  userPhoto,
  email_address,
  userDisplayName,
  userID,
  onMouseEnter,
  onMouseLeave,
  ownUserID,
  ownUserDisplayName,
  setPrivateMessageModalShow,
  setPrivateMesageModalData,
}) {
  const handleClickDirectMessage = async () => {
    setPrivateMesageModalData({ id: userID, display_name: userDisplayName });
    setPrivateMessageModalShow(true);
  };
  return (
    <div className='chat-user-preview' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className='user-image'>
        <img src={userPhoto} alt='' />
      </div>
      <div className='user-details'>
        <h5>{userDisplayName}</h5>
        <small>{email_address}</small>
        <div className='user-details-actions'>
          <div className='user-action' onClick={() => handleClickDirectMessage()}>
            <img src={chatImage} alt='' onClick={() => handleClickDirectMessage()} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserHoverPreview;
