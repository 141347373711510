import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const [redirectTimer, setRedirectTimer] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setRedirectTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          navigate("/");
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <div style={styles.container}>
      {/* Adding Keyframes for Animations */}
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
          @keyframes popUp {
            from { transform: scale(0.5); opacity: 0; }
            to { transform: scale(1); opacity: 1; }
          }
          @keyframes float {
            0% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0); }
          }
        `}
      </style>
      {/* Decorative Shapes */}
      <div style={styles.circle}></div>
      <div style={styles.smallCircle}></div>

      {/* Error Content */}
      <h1 style={styles.errorCode}>404</h1>
      <p style={styles.errorMessage}>Page Not Found</p>
      <p style={styles.additionalText}>
        Sorry, we couldn’t find the page you were looking for. It might have been moved or doesn’t exist anymore.
      </p>
      <p style={styles.tip}>Try checking the URL or return to the homepage.</p>
      <p style={styles.tip}>Redirecting you to the homepage in {redirectTimer} seconds.</p>

      {/* Action Button */}
      <button
        style={styles.button}
        onMouseOver={(e) => {
          e.target.style.backgroundColor = styles.buttonHover.backgroundColor;
          e.target.style.transform = styles.buttonHover.transform;
        }}
        onMouseOut={(e) => {
          e.target.style.backgroundColor = styles.button.backgroundColor;
          e.target.style.transform = "scale(1)";
        }}
        onClick={() => (window.location.href = "/")}
      >
        Go Back Home
      </button>

      {/* Decorative Icons */}
      <div style={styles.iconContainer}>
        <span style={styles.icon}>🚀</span>
        <span style={styles.icon}>📄</span>
        <span style={styles.icon}>🌐</span>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: "relative",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffebeb",
    overflow: "hidden",
    color: "#b30000",
    fontFamily: "'Arial', sans-serif",
    textAlign: "center",
    animation: "fadeIn 1s ease-in-out",
  },
  errorCode: {
    fontSize: "8rem",
    fontWeight: "bold",
    margin: "0",
    animation: "popUp 0.8s ease-in-out",
  },
  errorMessage: {
    fontSize: "2rem",
    margin: "10px 0",
    opacity: "0",
    animation: "fadeIn 1.5s ease-in-out forwards",
    animationDelay: "0.5s",
  },
  additionalText: {
    fontSize: "1.2rem",
    margin: "10px 0",
    opacity: "0",
    animation: "fadeIn 1.5s ease-in-out forwards",
    animationDelay: "1s",
  },
  tip: {
    fontSize: "1rem",
    margin: "10px 0",
    color: "#660000",
    opacity: "0",
    animation: "fadeIn 1.5s ease-in-out forwards",
    animationDelay: "1.5s",
  },
  button: {
    marginTop: "20px",
    padding: "12px 25px",
    fontSize: "1.1rem",
    color: "#fff",
    backgroundColor: "#b30000",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.2s, background-color 0.3s",
  },
  buttonHover: {
    backgroundColor: "#ff4d4d",
    transform: "scale(1.05)",
  },
  circle: {
    position: "absolute",
    top: "-100px",
    right: "-100px",
    width: "300px",
    height: "300px",
    backgroundColor: "#ffd4d4",
    borderRadius: "50%",
    zIndex: "-1",
    animation: "float 4s ease-in-out infinite",
  },
  smallCircle: {
    position: "absolute",
    bottom: "-50px",
    left: "-50px",
    width: "150px",
    height: "150px",
    backgroundColor: "#ffe0e0",
    borderRadius: "50%",
    zIndex: "-1",
    animation: "float 6s ease-in-out infinite",
  },
  iconContainer: {
    marginTop: "20px",
    display: "flex",
    gap: "15px",
    justifyContent: "center",
  },
  icon: {
    fontSize: "2rem",
    color: "#b30000",
    animation: "float 2s ease-in-out infinite",
  },
};

export default ErrorPage;
